@import "../main/abstracts/index";

.search {
    .page__header & {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    // height: $header__height--mobile;

    // @include media-breakpoint-up(lg) {
    //     height: $header__height--unpinned;
    // }

    // @include media-breakpoint-up(xxl) {
    //     height: $header__height;
    //     transition: height .3s ease-in-out;
    // }

    // html.headroom--unpinned & {
    //     @include media-breakpoint-up(xxl) {
    //         height: $header__height--unpinned;
    //     }
    // }
}
